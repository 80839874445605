<template>
  <div class="dataspec-container">
    <div class="data-bar">
      <span class="control-left">
        <span>{{ $t(`navbar.DataSpec`) }}</span>
      </span>
      <div>
        <span>
          <el-form
            :inline="true"  
            :model="filterOptions"
            label-position="right"
            label-width="60px"
            class="demo-form-inline"
          >
            <el-form-item
              prop="keyword"
            >
              <el-input
                v-model="filterOptions.keyword"
                :placeholder="$t('Form.placeholderKeyword')"
                suffix-icon="el-icon-search"
                clearable
                @keyup.native="onChangeFilter"
              />
            </el-form-item>
            <el-form-item 
              :label-width="'100'"
              :label="$t(`field.version`)"
            >
              <el-select
                v-model="filterOptions.selectedVersion"
                filterable
                @change="selectVersion"
              >
                <el-option
                  v-for="item in versions"
                  :key="item"
                  :label="item"
                  :value="item"
                />
              </el-select>
            </el-form-item>
          </el-form>
        </span>
      </div>
    </div>
    <el-tabs
      v-model="activePanel"
      type="card"
      tab-position="left"
      @tab-click="handleTabChange"
    >
      <template v-if="showBasicInfo">
        <el-tab-pane
          v-for="tab in tabs"
          :key="tab.name"
          :name="tab.name"
          :label="$t(`general.${tab.name}`)"
        >
          <BasicInfo 
            :table-data-basic="tableDataBasic"
          />
        </el-tab-pane>
      </template>
      <template v-else>
        <el-tab-pane
          v-for="tab in tabs"
          :key="tab.name"
          :name="tab.name"
          :label="$t(`general.${tab.name}`)"
          :disabled="isSearch"
        >
          <!-- search view -->
          <template v-if="isSearch">
            <template>
              <el-table
                :data="searchData"
                :cell-style="{ 'background-color': '#f3f2f1' }"
                :header-cell-style="{ 'background-color': '#f3f2f1' }"
              >
                <el-table-column :label="$t(`field.main_type`)" width="100">
                  <template slot-scope="scope">
                    {{ $t(`general.${scope.row.main_type}`) }}
                  </template>
                </el-table-column>
                <el-table-column :label="$t(`field.type`)" width="150">
                  <template slot-scope="scope">
                    <template v-if="scope.row.noSubtype">
                      <el-button 
                        @click="selectMainTypebySearch(scope.row.main_type, scope.row.type)"
                      >
                        {{ $t(`mainType.${scope.row.type}`) }}
                      </el-button>
                    </template>
                    <template v-else>
                      {{ $t(`mainType.${scope.row.type}`) }}
                    </template>
                  </template>
                </el-table-column>
                <el-table-column :label="$t(`field.subtype`)">
                  <template slot-scope="scope">
                    <template v-if="scope.row.noSubtype">
                      <span>{{ $t(`Spec.noData`) }}</span>
                    </template>
                    <template v-else>
                      <el-radio-group 
                        v-model="selectedSubType"
                        fill="#909399"
                        @change="selectsubTypebySearch(scope.row.main_type, scope.row.type, selectedSubType )"
                      >
                        <el-radio-button 
                          v-for="(item,index) in scope.row.subtype"
                          :key="index"
                          :label="item"
                        >
                          {{ $t(`subType.${item}`) }}
                        </el-radio-button>
                      </el-radio-group>
                    </template>
                  </template>
                </el-table-column>
                <template slot="empty">
                  <el-empty :description="$t('general.NoData')" />
                </template>
              </el-table>
              <el-row v-if="hasSearchData">
                <DataList :table-data="tableData" />
              </el-row>
            </template>
          </template>
          <!-- default view -->
          <template v-else>
            <el-row class="datatype-row">
              <el-col :span="3" class="datatype-th">{{ $t(`field.type`) }}</el-col>
              <el-col :span="21" class="datatype-options">
                <el-radio-group 
                  v-model="selectedType"
                  fill="#909399"
                  @change="selectMainType"
                >
                  <el-radio-button 
                    v-for="(item,index) in maintype"
                    :key="index"
                    :label="item"
                  >
                    {{ $t(`mainType.${item}`) }}
                  </el-radio-button>
                </el-radio-group>
              </el-col>
            </el-row>
            <el-row class="datatype-row">
              <el-col :span="3" class="datatype-th">{{ $t(`field.subtype`) }}</el-col>
              <el-col :span="21" class="datatype-options">
                <template v-if="noSubtype">
                  <div class="nodata">{{ $t(`Spec.noData`) }}</div>
                </template>
                <el-radio-group 
                  v-model="selectedSubType"
                  fill="#909399"
                  @change="selectSubType"
                >
                  <el-radio-button 
                    v-for="(item,index) in subtype"
                    :key="index"
                    :label="item"
                  >
                    {{ $t(`subType.${item}`) }}
                  </el-radio-button>
                </el-radio-group>
              </el-col>
            </el-row>
            <el-row>
              <DataList :table-data="tableData" />
            </el-row>
          </template>
        </el-tab-pane>
      </template>
    </el-tabs>
    <!-- actoin-block button -->
    <template v-if="showDownloadButtons">
      <el-row>
        <el-col class="actoin-block">
          <el-button 
            v-for="(item,index) in actionButtons"
            :key="index"
            @click="getTemplate(item.value)"
          >
            {{ item.name }}
            <i v-if="item.isIcon" class="el-icon-download el-icon--right" />
          </el-button>
        </el-col>
      </el-row>
    </template>
    <DialogApi 
      v-if="dialogVisible" 
      :dialog-visible="dialogVisible"
      :api-body="ApiBody"
      :open-api-link="openApiLink"
      @closeDialog="emitCloseDialog"
    />
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { getDatatype } from '@/api/data'
import { 
  getDataSpec,
  getDataTemplate,
  getApiBody,
  getBasicSpec,
  getSearchData
} from '@/api/specification'
import { downloadFileFromBinary } from '@/utils/downloadFile'
import DialogApi from './components/DialogApi.vue'
import DataList from './components/DataList.vue'
import BasicInfo from './components/BasicInfo.vue'

const params = () => ({
  main_type: 'Sport', // 資料列別
  type: '', 
  subtype: '',
  version: null
})

export default {
  name: 'DataSpec',
  components: { DialogApi, DataList, BasicInfo },
  data () {
    return {
      params: params(),
      tabs: [
        { name: 'Sport' },
        { name: 'PhysicalFitness' },
        { name: 'Physiology' },
        { name: 'BasicInfo' }
      ],
      actionButtons: [
        // { name: 'ODS', value: 'ods', isIcon: true },
        // { name: 'CSV', value: 'csv', isIcon: true },
        // { name: 'Excel', value: 'xlsx', isIcon: true },
        { name: 'API Body', value: 'API', isIcon: false }
      ],
      filterOptions: {
        selectedVersion: null,
        keyword: ''
      },
      allOptions: null,
      activePanel: 'Sport',
      selectedType: '',
      selectedSubType: '',
      // keyword: '',
      noSubtype: false,
      showBasicInfo: false,
      hasSearchData: false,
      version: [],
      tableData: [],
      searchData: [],
      tableDataBasic: [],
      maintype: [],
      subtype: [],
      paraStr: '',
      openApiLink: null,
      ApiBody: null,
      downloadType: '',
      dialogVisible: false
    }
  },
  computed: {
    ...mapGetters(['versions']),
    versionOptions() {
      return this.versions
    },
    isSearch() {
      return this.filterOptions.keyword !== ''
    },
    showSearchTable() {
      return this.isSearch && this.hasSearchData
    },
    showDownloadButtons() {
      return !this.showBasicInfo || this.hasSearchData
    }
  },
  mounted() {
    this.fetchInfo()
    this.loadData()
  },
  methods: {
    loadData () {
      this.getParaStr()
    },
    async fetchInfo() {
      this.allOptions = (await getDatatype()).data
      this.params.version = this.versionOptions[0]
      this.filterOptions.selectedVersion = this.versionOptions[0]
      this.setMainTypeOptiones()
      this.setSubTypeOptiones()
      this.fetchData()
    },
    async fetchData() {
      this.listLoading = true
      const res = await getDataSpec(this.params)
      this.tableData = res.data
      this.listLoading = false
    },
    async fetchBasicData() {
      this.listLoading = true
      const res = await getBasicSpec()
      this.tableDataBasic = res.data
      this.listLoading = false
    },
    async onChangeFilter() {
      this.hasSearchData = false
      const para = {
        search: this.filterOptions.keyword
      }
      const res = await getSearchData(para)
      this.searchData = res.data.map((item) => {
        const newObj = {
          ...item,
          noSubtype: item.subtype.length === 0
        }
        return newObj
      })
      this.showBasicInfo = false
    },
    selectMainTypebySearch(main_type, type) {
      this.params.main_type = main_type
      this.params.type = type
      this.params.subtype = ''
      this.hasSearchData = true
      this.fetchData()
    },
    selectsubTypebySearch(main_type, type, subtype) {
      this.params.main_type = main_type
      this.params.type = type
      this.params.subtype = subtype
      this.hasSearchData = true
      this.fetchData()
    },
    setMainTypeOptiones() {
      this.maintype = Object.keys(this.allOptions[this.activePanel])
      this.params.type = this.maintype[0]
      this.selectedType = this.maintype[0]
    },
    setSubTypeOptiones() {
      this.subtype = this.allOptions[this.activePanel][this.selectedType]
      if (!this.subtype.length) {
        this.noSubtype = true
      } else {
        this.noSubtype = false
      }
      this.params.subtype = this.subtype[0]
      this.selectedSubType = this.subtype[0]
    },
    getParaStr() {
      const strArr = []
      let paraStr = ''
      const obj = this.params
      for (const p in obj) {
        if (obj[p]) {
          strArr.push(p + '=' + obj[p])
        }
      }
      paraStr = strArr.join('&')
      this.paraStr = paraStr
    },
    handleTabChange() {
      if (this.activePanel === 'BasicInfo') {
        this.showBasicInfo = true
        this.fetchBasicData()
      } else {
        this.showBasicInfo = false
        this.params.main_type = this.activePanel
        this.setMainTypeOptiones()
        this.setSubTypeOptiones()
        this.fetchData()
      }
    },
    selectMainType() {
      this.params.type = this.selectedType
      this.setSubTypeOptiones()
      this.fetchData()
    },
    selectSubType() {
      this.params.subtype = this.selectedSubType
      this.fetchData()
    },
    selectVersion() {
      this.params.version = this.filterOptions.selectedVersion
      this.fetchData()
    },
    fetchGetTemplate() {
      this.listLoading = true
      
      getDataTemplate(this.params)
        .then((res) => {
          downloadFileFromBinary(res, `template.${this.downloadType}`, this.downloadType)
        })
        .catch((error) => console.error(error))
      this.listLoading = false
    },
    async fetchgetApiBody() {
      this.listLoading = true
      const res = await getApiBody(this.params)
      this.openApiLink = res.swagger_url 
      this.ApiBody = res.data
      this.listLoading = false
    },
    getTemplate(actionType) {
      if (actionType === 'API') {
        this.dialogVisible = true
        this.fetchgetApiBody()
      } else {
        this.params.file_format = actionType
        this.downloadType = actionType
        this.fetchGetTemplate()
      }
    },
    emitCloseDialog() {
      this.dialogVisible = false
    }
  }
}
</script>

<style lang="scss" scoped>
@import "src/styles/index.scss";
.dataspec-container {
  text-align: center;
  .data-bar {
    display:flex;
    justify-content: space-between;
    .control-left{
      text-align: left;
    }
    .el-form-item{
      margin-bottom: 0px;
    }
  }
  .el-tab-pane{
    border-left: 1px solid $info;
  }
  flex-direction: column;
  .datatype-row{
    border-bottom: 1px solid $info;
    padding: 20px 0;

    .datatype-th{
      padding-top: 10px;
    }
    .datatype-options{
      text-align: left;
      
      .nodata{
        color: $text;
        font-size: 1rem;
        padding-top: 15px;
      }
    }
  }
  .actoin-block{
    padding-top: 10px;
  }
  
}
</style>
